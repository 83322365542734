import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueScrollTo from 'vue-scrollto'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/font/iconfont'
import './assets/style/common.css'
import './assets/style/variable.less'

const app = createApp(App)
app.use(store).use(router).use(vueScrollTo).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
