import { createStore } from 'vuex'

export default createStore({
  state: {
    BannerHeight: 0,
    ImageHeight: 0,
    headerShadowActive: false,
    headerShow: false,
    headerLogoShow: true,
    navDarkActive: false,
    articlePath: '',
    titles: [] as any[]
  },
  mutations: {
    setBannerHeight (state, value) {
      state.BannerHeight = value.bannerHeight
    },
    setImageHeight (state, value) {
      state.ImageHeight = value.ImageHeight
    },
    setShadowActive (state, value) {
      state.headerShadowActive = value.headerShadowActive
    },
    setHeaderShow (state, value) {
      state.headerShow = value.headerShow
    },
    setHeaderLogo (state, value) {
      state.headerLogoShow = value.headerLogoShow
    },
    setNavDarkActive (state, value) {
      state.navDarkActive = value.navDarkActive
    },
    SET_ARTICLE_PATH: (state, value) => {
      state.articlePath = value.path
    },
    setTitles(state, value) {
      state.titles = value.titles
    }
  },
  actions: {
  },
  getters: {
    getTitles (state) {
      return state.titles
    }
  },
  modules: {
  }
})
