import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index',
    redirect: '/',
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/',
    name: 'index',
    component: HomeView,
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "newsDetail" */ '../views/NewsDetail.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/product/:path',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductDetail.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/core_value',
    name: 'core_value',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CoreValueView.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  },
  {
    path: '/contact_us',
    name: 'contact_us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUsView.vue'),
    meta: {
      title: '凯宏睿科技有限公司'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
